@charset 'utf-8';
@import "reset";
@import "variables";

$sp: 768px;  // スマホ

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

body p,li{
    font: 14px;
    font-family: "游ゴシック体",'futura-pt','Montserrat', YuGothic, "游ゴシック", "Yu Gothic", Arial, Helvetica, sans-serif;
    letter-spacing: 1.2px;
    line-height: 1.5;
    @include sp{
        line-height: 1.2;
        font-size: 14px;
    }
};

.l-container{
    margin: 0 auto;
}
.l-inner{
    margin: 0 auto;
    margin-left: 10%;
}
.l-marginTop_SS{
    margin-top: 10px;
}
.l-marginTop_S{
    margin-top: 20px;
}
.l-marginTop_M{
    margin-top: 40px;
}
.l-marginTop_L{
    margin-top: 60px;
}

.l-paddingTop_SS{
    padding-top: 10px;
}
.l-paddingTop_S{
    padding-top: 20px;
}
.l-paddingTop_M{
    padding-top: 40px;
}
.l-paddingTop_L{
    padding-top: 60px;
}

.f-head{
    text-align: left;
    font: Bold 28px/48px YuGothic;
    letter-spacing: 1.12px;
    line-height: 1.5;
    color: #000000;
    opacity: 1;
    @include sp{
        font-size: 20px;
    }
}
.f-title{
    text-align: left;
    font: Medium 24px/32px Futura;
    letter-spacing: 0.96px;
    color: #000000;
    opacity: 1;
}
.f-side{
    letter-spacing: 0.64px;
    color: #000000;
    opacity: 0.6;
}
.h-body{
    text-align: left;
    font: 14px/24px YuGothic;
    letter-spacing: 0.56px;
    color: #000000;
    opacity: 1;
}
.h-body_B{
    text-align: left;
    font: bold 14px/24px YuGothic;
    letter-spacing: 0.56px;
    color: #000000;
    opacity: 1;
}
.f-body_S{
    text-align: left;
    font: 12px/20px YuGothic;
    letter-spacing: 0.48px;
    color: #000000;
    opacity: 1;
}

.AboutUs, .WhatWeDo, .CompanyInfo, .ContactUs{
    position: relative;
    .header{
        width: 100%;
        &__head{
            margin: 0 auto;
            text-align: center;
            &--main{
                font-size: 28px;
                letter-spacing: 2px;
            }
            &--sub{
                font-size: 12px;
                letter-spacing: 2px;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: -10px;/*線の上下位置*/
                    display: inline-block;
                    width: 20px;/*線の長さ*/
                    height: 3px;/*線の太さ*/
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);/*位置調整*/
                    background-color: #000C9B;/*線の色*/
                    border-radius: 3px;/*線の丸み*/
                }
            }
        }
    }
    .side{
        position: absolute;
        transform: matrix(0, 1, -1, 0, 0, 0);
        top: 0px;
        right: 0px;
        margin-right: -60px;
        margin-top: 220px;
        @include sp{
            display: none;
        }
    }
    .main{
        width: 1050px;
        margin: 0 auto;
        @include sp{
            width: 90%;
            margin: 0 auto;
        }
    }
    .header{
        margin-bottom: 60px;
    }
}


.Top{
    width: 1050px;
    margin: 0 auto;
    @include sp{
        width: 90%;
        margin:  0 auto;
    }
    .MainVisual{
        position: absolute;
        top: -80px;
        right: 0px;
        z-index: -1;
        width: 1100px;
        img{
            width: 100%;
        }
        @include sp{
            overflow: hidden;
            top: -80px;
            left: 0;
            width: 100%;
            img{
                width: 1113px;
                
            }
        }
    }
    .main{
        margin-top: 250px;
        &__logo{
            width: 100%;
            @include sp{
                img{
                    width: 70%;
                }
            }
        }
        &__icon{
            @include sp{
                img{
                    width: 10%;
                }
            }
        }
    }
    .sub{
        margin-top: 140px;
        &__text{
            font-family: 'futura-pt';
            font-size: 18px;
        }
    }
}



.AboutUs{
    background-color: #ffebeb;
    position: relative;
    padding-bottom: 100px;
    @include sp{
        margin-top: -3px;
    }
    
    .main{
        &__head{
            @include sp{
                font-size: 20px;
            }
        }
    }
    .main__sub{
        width: 750px;
        @include sp{
            width: auto;
        }
    }
}

.WhatWeDo{
    margin-bottom: 100px;
    padding-bottom: 100px;
    margin: 0 auto;
    .header{
        margin-bottom: 60px;
    }


    .main{
        justify-content: space-around;
        display: flex;
        @include sp{
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .mainPanel{
            width: 300px;
            @include sp{
                margin-bottom: 30px;
            }
            &__image{
                text-align: center;
            }
            &__head{
                text-align: center;
            }
        }
    }
    .side{
        margin-right: -25px;
    }
}

.CompanyInfo{
    background-color: #d2e2ff;
    padding-bottom: 100px;

    .main{
        display: flex;
        @include sp{
            flex-direction: column;
            flex-wrap: wrap;
        }
        .mainInfo{
            @include sp{
                margin-bottom: 30px;
            }
            &__content{
                display: flex;
                li.title{
                    width: 120px;
                    margin-bottom: 10px;
                }
                li.text{
             
                    margin-bottom: 10px;
                    width: 100%;
                    margin-left: 30px;
                    @include sp{
                        width: 100%;;
                        margin-left:0; 
                    }
                }
            }
        }

    }
    .subMap{
        margin-left: 40px;
        @include sp{
            width: 100%;
            margin-left: 0px;
            iframe{
                width: 100%;
            }
        }
        
    }
    .side{
        margin-right: -35px;
    }
}

.ContactUs{
    .header{
        margin-bottom: 60px;
    }
    .main{
        text-align: center;
        position: relative;

        &__test--left{
            position: absolute;
            margin-left: -100px;
            margin-top: -15px;
        }
        &__test--right{
            position: absolute;
            margin-left: 75px;
            margin-top: -15px;
        }
    }
    .sub{
        text-align: center;
    }
    .sub__fb{

    }
}
.Footer{
    margin-bottom: 20px;
    @include sp{
        margin-left: 5%;
        .main{
            
            &__desc p{
                font-size: 10px;
            }
        }
    }
}