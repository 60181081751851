@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif; }

/*  HTML5 display-role reset for older browsers
*/
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body p, li {
  font: 14px;
  font-family: "游ゴシック体",'futura-pt','Montserrat', YuGothic, "游ゴシック", "Yu Gothic", Arial, Helvetica, sans-serif;
  letter-spacing: 1.2px;
  line-height: 1.5; }
  @media (max-width: 768px) {
    body p, li {
      line-height: 1.2;
      font-size: 14px; } }

.l-container {
  margin: 0 auto; }

.l-inner {
  margin: 0 auto;
  margin-left: 10%; }

.l-marginTop_SS {
  margin-top: 10px; }

.l-marginTop_S {
  margin-top: 20px; }

.l-marginTop_M {
  margin-top: 40px; }

.l-marginTop_L {
  margin-top: 60px; }

.l-paddingTop_SS {
  padding-top: 10px; }

.l-paddingTop_S {
  padding-top: 20px; }

.l-paddingTop_M {
  padding-top: 40px; }

.l-paddingTop_L {
  padding-top: 60px; }

.f-head {
  text-align: left;
  font: Bold 28px/48px YuGothic;
  letter-spacing: 1.12px;
  line-height: 1.5;
  color: #000000;
  opacity: 1; }
  @media (max-width: 768px) {
    .f-head {
      font-size: 20px; } }

.f-title {
  text-align: left;
  font: Medium 24px/32px Futura;
  letter-spacing: 0.96px;
  color: #000000;
  opacity: 1; }

.f-side {
  letter-spacing: 0.64px;
  color: #000000;
  opacity: 0.6; }

.h-body {
  text-align: left;
  font: 14px/24px YuGothic;
  letter-spacing: 0.56px;
  color: #000000;
  opacity: 1; }

.h-body_B {
  text-align: left;
  font: bold 14px/24px YuGothic;
  letter-spacing: 0.56px;
  color: #000000;
  opacity: 1; }

.f-body_S {
  text-align: left;
  font: 12px/20px YuGothic;
  letter-spacing: 0.48px;
  color: #000000;
  opacity: 1; }

.AboutUs, .WhatWeDo, .CompanyInfo, .ContactUs {
  position: relative; }
  .AboutUs .header, .WhatWeDo .header, .CompanyInfo .header, .ContactUs .header {
    width: 100%; }
    .AboutUs .header__head, .WhatWeDo .header__head, .CompanyInfo .header__head, .ContactUs .header__head {
      margin: 0 auto;
      text-align: center; }
      .AboutUs .header__head--main, .WhatWeDo .header__head--main, .CompanyInfo .header__head--main, .ContactUs .header__head--main {
        font-size: 28px;
        letter-spacing: 2px; }
      .AboutUs .header__head--sub, .WhatWeDo .header__head--sub, .CompanyInfo .header__head--sub, .ContactUs .header__head--sub {
        font-size: 12px;
        letter-spacing: 2px;
        position: relative; }
        .AboutUs .header__head--sub::before, .WhatWeDo .header__head--sub::before, .CompanyInfo .header__head--sub::before, .ContactUs .header__head--sub::before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -10px;
          /*線の上下位置*/
          display: inline-block;
          width: 20px;
          /*線の長さ*/
          height: 3px;
          /*線の太さ*/
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          /*位置調整*/
          background-color: #000C9B;
          /*線の色*/
          border-radius: 3px;
          /*線の丸み*/ }
  .AboutUs .side, .WhatWeDo .side, .CompanyInfo .side, .ContactUs .side {
    position: absolute;
    transform: matrix(0, 1, -1, 0, 0, 0);
    top: 0px;
    right: 0px;
    margin-right: -60px;
    margin-top: 220px; }
    @media (max-width: 768px) {
      .AboutUs .side, .WhatWeDo .side, .CompanyInfo .side, .ContactUs .side {
        display: none; } }
  .AboutUs .main, .WhatWeDo .main, .CompanyInfo .main, .ContactUs .main {
    width: 1050px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .AboutUs .main, .WhatWeDo .main, .CompanyInfo .main, .ContactUs .main {
        width: 90%;
        margin: 0 auto; } }
  .AboutUs .header, .WhatWeDo .header, .CompanyInfo .header, .ContactUs .header {
    margin-bottom: 60px; }

.Top {
  width: 1050px;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .Top {
      width: 90%;
      margin: 0 auto; } }
  .Top .MainVisual {
    position: absolute;
    top: -80px;
    right: 0px;
    z-index: -1;
    width: 1100px; }
    .Top .MainVisual img {
      width: 100%; }
    @media (max-width: 768px) {
      .Top .MainVisual {
        overflow: hidden;
        top: -80px;
        left: 0;
        width: 100%; }
        .Top .MainVisual img {
          width: 1113px; } }
  .Top .main {
    margin-top: 250px; }
    .Top .main__logo {
      width: 100%; }
      @media (max-width: 768px) {
        .Top .main__logo img {
          width: 70%; } }
    @media (max-width: 768px) {
      .Top .main__icon img {
        width: 10%; } }
  .Top .sub {
    margin-top: 140px; }
    .Top .sub__text {
      font-family: 'futura-pt';
      font-size: 18px; }

.AboutUs {
  background-color: #ffebeb;
  position: relative;
  padding-bottom: 100px; }
  @media (max-width: 768px) {
    .AboutUs {
      margin-top: -3px; } }
  @media (max-width: 768px) {
    .AboutUs .main__head {
      font-size: 20px; } }
  .AboutUs .main__sub {
    width: 750px; }
    @media (max-width: 768px) {
      .AboutUs .main__sub {
        width: auto; } }

.WhatWeDo {
  margin-bottom: 100px;
  padding-bottom: 100px;
  margin: 0 auto; }
  .WhatWeDo .header {
    margin-bottom: 60px; }
  .WhatWeDo .main {
    justify-content: space-around;
    display: flex; }
    @media (max-width: 768px) {
      .WhatWeDo .main {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; } }
    .WhatWeDo .main .mainPanel {
      width: 300px; }
      @media (max-width: 768px) {
        .WhatWeDo .main .mainPanel {
          margin-bottom: 30px; } }
      .WhatWeDo .main .mainPanel__image {
        text-align: center; }
      .WhatWeDo .main .mainPanel__head {
        text-align: center; }
  .WhatWeDo .side {
    margin-right: -25px; }

.CompanyInfo {
  background-color: #d2e2ff;
  padding-bottom: 100px; }
  .CompanyInfo .main {
    display: flex; }
    @media (max-width: 768px) {
      .CompanyInfo .main {
        flex-direction: column;
        flex-wrap: wrap; } }
    @media (max-width: 768px) {
      .CompanyInfo .main .mainInfo {
        margin-bottom: 30px; } }
    .CompanyInfo .main .mainInfo__content {
      display: flex; }
      .CompanyInfo .main .mainInfo__content li.title {
        width: 120px;
        margin-bottom: 10px; }
      .CompanyInfo .main .mainInfo__content li.text {
        margin-bottom: 10px;
        width: 100%;
        margin-left: 30px; }
        @media (max-width: 768px) {
          .CompanyInfo .main .mainInfo__content li.text {
            width: 100%;
            margin-left: 0; } }
  .CompanyInfo .subMap {
    margin-left: 40px; }
    @media (max-width: 768px) {
      .CompanyInfo .subMap {
        width: 100%;
        margin-left: 0px; }
        .CompanyInfo .subMap iframe {
          width: 100%; } }
  .CompanyInfo .side {
    margin-right: -35px; }

.ContactUs .header {
  margin-bottom: 60px; }

.ContactUs .main {
  text-align: center;
  position: relative; }
  .ContactUs .main__test--left {
    position: absolute;
    margin-left: -100px;
    margin-top: -15px; }
  .ContactUs .main__test--right {
    position: absolute;
    margin-left: 75px;
    margin-top: -15px; }

.ContactUs .sub {
  text-align: center; }

.Footer {
  margin-bottom: 20px; }
  @media (max-width: 768px) {
    .Footer {
      margin-left: 5%; }
      .Footer .main__desc p {
        font-size: 10px; } }
